<template>
  <div>
    <div class="hero-area">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-xl-7">
            <div class="hero-content">
              <h3>Konversi Pulsa dan E-Wallet</h3>
              <p>Nak konversi pulsa samo ewallet payo disini bae</p>
            </div>
          </div>

          <div class="col-lg-7 col-xl-6">
            <div class="subscription-form">
              <b-form-group label="Mau konversi apa ?">
                <b-form-radio-group
                  id="btn-jenis-konversi"
                  v-model="form.layanan"
                  :options="options.layanan"
                  button-variant="outline-success"
                  size="lg"
                  name="radio-btn-outline"
                  buttons
                  class="btn-block"
                  @input="changeLayanan"
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group label="Providernya apa ?">
                <b-form-select
                  v-model="form.provider"
                  :options="
                    form.layanan == 'pulsa' ? options.operator : options.ewallet
                  "
                  size="lg"
                ></b-form-select>
              </b-form-group>
              <b-form-group label="Nominalnya berapa ?">
                <b-form-input
                  v-model="form.nominal"
                  type="number"
                  @input="
                    form.layanan == 'pulsa' ? hitungPulsa() : hitungEwallet()
                  "
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Kamu akan dapet duit segini">
                <b-form-input
                  v-model="form.hasil"
                  type="number"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div><br>
            <center><h6>Kontak Dibawah ini</h6></center><br>
			      <center><img src="https://panel.skyn.tech/img/tele.jpg" alt="Telegram" width="20" height="20"><a href="https://t.me/myskynblack" target="_blank"><h5>@myskynblack</h5></a>
			      <img src="https://panel.skyn.tech/img/wa.png" alt="Whatsapp" width="20" height="20"><a href="https://api.whatsapp.com/send?phone=6282304043636" target="_blank"><h5>082304043636</h5></a></center>
          </div>
        </div>
      </div>
    </div>
    <section class="pricing-section section-spacing">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-7 col-md-8 col-sm-11">
            <div class="section-title pb-lg--85">
              <span class="title-sm-text">It’s time to taste it</span>
              <h2>Nilai Tukar (Rate)</h2>
              <span class="title-sm-text"
                >Rate adalah Nilai Tukar Pulsa ke Rupiah</span
              >
            </div>
          </div>
        </div>

        <div class="row mb-d-30">
          <div class="col-lg-12 mb--30">
            <div class="pricing-card">
              <div class="card-content">
                <b-table
                  head-variant="dark"
                  borderless
                  hover
                  :fields="rates.field"
                  :items="rates.data"
                ></b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.pricing-section {
  background-image: url(../assets/image/pricing-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 500px;
}
</style>

<script>
export default {
  name: "Home",
  data() {
    return {
      form: {
        layanan: "pulsa",
        provider: "tsel",
        nominal: 0,
        hasil: 0,
      },
      options: {
        layanan: [
          { text: "PULSA", value: "pulsa" },
          { text: "E-WALLET", value: "ewallet" },
        ],
        operator: [
          { text: "INDOSAT", value: "isat" },
          { text: "TELKOMSEL", value: "tsel" },
          { text: "THREE", value: "three" },
          { text: "XL AXIATA", value: "xl" },
        ],
        ewallet: [{ text: "DANA/GOPAY/OVO/SHOPEEPAY", value: "0" }],
      },
      rates: {
        field: [
          {
            key: "operator",
            label: "OPERATOR",
            variant: "success",
          },
          {
            key: "nilai1",
            label: "25-99 RIBU",
          },
          {
            key: "nilai2",
            label: "100-199 RIBU",
          },
          {
            key: "nilai3",
            label: "200-999 RIBU",
          },
          {
            key: "nilai4",
            label: "> 1 JUTA",
          },
        ],
        data: [
          {
            operator: "INDOSAT",
            nilai1: 65,
            nilai2: 67,
            nilai3: 68,
            nilai4: 70,
          },
          {
            operator: "TELKOMSEL",
            nilai1: 75,
            nilai2: 76,
            nilai3: 77,
            nilai4: 78,
          },
          {
            operator: "THREE",
            nilai1: 65,
            nilai2: 67,
            nilai3: 68,
            nilai4: 70,
          },
          {
            operator: "XL AXIATA",
            nilai1: 65,
            nilai2: 67,
            nilai3: 68,
            nilai4: 70,
          },
        ],
      },
    };
  },
  methods: {
    changeLayanan() {
      const self = this;
      self.form.provider = self.form.layanan == "pulsa" ? "tsel" : "0";
      self.form.nominal = 0;
      self.form.hasil = 0;
    },
    hitungPulsa() {
      const self = this;
      if (self.form.nominal < 25000) {
        self.form.hasil = 0;
      } else {
        let index =
          self.form.provider == "isat"
            ? 0
            : self.form.provider == "tsel"
            ? 1
            : self.form.provider == "three"
            ? 2
            : 3;

        let pengali;
        if (self.form.nominal >= 25000 && self.form.nominal <= 99000) {
          pengali = "nilai1";
        } else if (self.form.nominal >= 100000 && self.form.nominal <= 199000) {
          pengali = "nilai2";
        } else if (self.form.nominal >= 200000 && self.form.nominal <= 999000) {
          pengali = "nilai3";
        } else if (self.form.nominal >= 1000000) {
          pengali = "nilai4";
        }

        /* console.log(index);
        console.log(pengali);
        console.log(self.form.nominal);
        console.log(self.rates.data[index][pengali] / 100);
        console.log(self.form.nominal *(self.rates.data[index][pengali] / 100));
        console.log(self.form.nominal -
          (self.form.nominal * (self.rates.data[index][pengali] / 100))); */

        self.form.hasil =
          (self.form.nominal * (self.rates.data[index][pengali] / 100));
      }
    },
    hitungEwallet() {
      const self = this;

      if (self.form.nominal <= 1000) {
        self.form.hasil = 0;
      } else {
        self.form.hasil = self.form.nominal - Math.ceil(self.form.nominal / 25000) * 1000;
      }
    },
  },
};
</script>
